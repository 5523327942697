























































































































































































































































































































































































































































































































.img_ava {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
